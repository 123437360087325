import { Article } from '../types'

export const pricePerItem = (item: Article) => {
  const modifierPrice =
    (item.amountPriceModifierTakeAway && item.modifierTypeTakeAway
      ? item.modifierTypeTakeAway === 'surplus'
        ? item.amountPriceModifierTakeAway
        : -item.amountPriceModifierTakeAway
      : 0) +
    (item.orderModule
      ? item.orderModule.reduce(
          (partSum, mod) =>
            (mod.modifierTypeTakeAway
              ? mod.modifierTypeTakeAway === 'surplus'
                ? mod.priceModifierTakeAway
                : -mod.priceModifierTakeAway
              : 0) *
              mod.quantity +
            partSum,
          0
        )
      : 0)
  const itemPrice =
    item.price +
    (item.orderModule
      ? item.orderModule.reduce(
          (partSum, mod) =>
            (mod.modifierType && mod.priceModifier
              ? mod.modifierType === 'surplus'
                ? mod.priceModifier
                : -mod.priceModifier
              : 0) *
              mod.quantity +
            partSum,
          0
        )
      : 0)
  return {
    price: itemPrice,
    modifierTakeAway: modifierPrice,
  }
}
