import classNames from 'classnames'
import Image from 'next/future/image'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Collapse,
  Container,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  Offcanvas,
  NavDropdown,
  CloseButton,
} from 'react-bootstrap'
import styles from './Header.module.scss'
import { useRouter } from 'next/router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEarthEurope, faRightToBracket, faInfoCircle, faUtensils } from '@fortawesome/free-solid-svg-icons'
import { GB, DE } from 'country-flag-icons/react/3x2'
import i18n from '../../services/i18n'

const Header = ({ darkLogoOnPaths, setDarkLogoOnPaths }: { darkLogoOnPaths: any; setDarkLogoOnPaths: any }) => {
  const router = useRouter()
  const { query } = router
  const { t } = useTranslation(['common'])
  const [navBackground, setNavBackground] = useState(false)
  const [path, setPath] = useState<null | string>(null)
  const offCanvasRef = useRef()
  const navRef = useRef(false)
  navRef.current = navBackground
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 50
      if (navRef.current !== show) {
        setNavBackground(show)
      }
    }
    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (router.isReady) {
      setPath(router.asPath)
    }
  }, [router, query])

  useEffect(() => {
    if (router.isReady) {
      if (router.pathname.includes('checkout')) {
        setDarkLogoOnPaths(true)
      } else {
        setDarkLogoOnPaths(false)
      }
    }
  }, [router])

  return (
    <>
      {['lg'].map((expand, index) => (
        <Navbar
          expand="lg"
          fixed="top"
          key={index}
          className={classNames('px-5', styles.navbarContainer, {
            [styles.navbarOpaque]: navBackground,
          })}
        >
          <Container fluid className="d-flex justify-content-center justify-content-md-start me-auto">
            <Navbar.Toggle
              className={classNames('background-transparent', 'border-0', 'start-0', styles.navbarToggler)}
              onClick={function noRefCheck() {}}
            />
            <NavbarBrand
              onClick={() => router.push('/', '/', { locale: i18n.language })}
              className={classNames('d-flex', 'align-items-center', styles.navbarLogoContainer)}
            >
              <Image
                src="/sizzlyLogo.svg"
                alt="Sizzly GmbH Logo"
                width={150}
                height={50}
                priority
                className={classNames('position-absolute', styles.navbarLogo, {
                  [styles.imageAlter]: !navBackground && !darkLogoOnPaths,
                })}
              />
              <Image
                src="/sizzlyLogoAlt.svg"
                alt="Sizzly GmbH Logo"
                width={150}
                height={50}
                priority
                className={classNames('', router.asPath.includes('restaurants') && styles.fillSvg, styles.navbarLogo, {
                  [styles.imageAlter]: navBackground && darkLogoOnPaths,
                })}
              />
            </NavbarBrand>
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="start"
              // @ts-expect-error
              ref={offCanvasRef}
            >
              <Offcanvas.Header closeButton closeVariant="white" className="text-center color-white">
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`} className=" mx-auto text-uppercase">
                  <button
                    onClick={() => {
                      router.push('/', '/', { locale: i18n.language })
                      // @ts-expect-error
                      if (offCanvasRef.current?.backdrop) {
                        // @ts-expect-error
                        offCanvasRef.current.backdrop.click()
                      }
                    }}
                    className="btn"
                  >
                    <Image src="/sizzly_logo_white.png" alt="Sizzly GmbH Logo" width={150} height={60} priority />
                  </button>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav navbar className="ms-auto">
                  <NavItem>
                    <Button
                      onClick={() => {
                        router.push('/restaurants', '/restaurants', { locale: i18n.language })
                        // @ts-expect-error
                        if (offCanvasRef.current?.backdrop) {
                          // @ts-expect-error
                          offCanvasRef.current.backdrop.click()
                        }
                      }}
                      className={classNames('rounded-pill', 'ms-2', styles.navbarButton, styles.navbarButtonPrimary)}
                    >
                      <FontAwesomeIcon icon={faUtensils} /> <span className="ps-1">RESTAURANTS</span>
                    </Button>
                  </NavItem>
                  <NavItem>
                    <Button
                      href="https://gastro.sizzly.de"
                      className={classNames('rounded-pill', 'ms-2', styles.navbarButton, styles.navbarButtonPrimary)}
                    >
                      <FontAwesomeIcon icon={faInfoCircle} /> {t('common:gastroInfo')}
                    </Button>
                  </NavItem>
                  <NavItem>
                    <Button
                      href="https://app.sizzly.de"
                      className={classNames('rounded-pill', styles.navbarButton, styles.navbarButtonSecondary)}
                    >
                      <FontAwesomeIcon icon={faRightToBracket} /> {t('common:gastroLogin')}
                    </Button>
                  </NavItem>
                  <NavDropdown
                    title={<FontAwesomeIcon icon={faEarthEurope} className={styles.earthContent} />}
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                    className={classNames(
                      'rounded-circle align-end',
                      styles.navbarButtonSecondary,
                      styles.offcanvasSmall
                    )}
                  >
                    <NavDropdown.Item
                      onClick={() => {
                        router.push(`${path}`, `${path}`, { locale: 'de' })
                        // @ts-expect-error
                        offCanvasRef.current.backdrop.click()
                      }}
                    >
                      <DE title="EN" width="15px" height="45px" /> DE
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={() => {
                        router.push(`${path}`, `${path}`, { locale: 'en' })
                        // @ts-expect-error
                        offCanvasRef.current.backdrop.click()
                      }}
                    >
                      <GB title="EN" width="15px" height="45px" className="m-auto" /> EN
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  )
}

export default Header
