import axios from 'axios'
import Cookie from 'js-cookie'
import { API_URL } from '../config/config'

type HttpMethod = 'get' | 'post' | 'delete' | 'patch' | 'put'

export const makeRequest = async (method: HttpMethod, url: string, data?: any): Promise<any> => {
  const token = Cookie.get('token')
  let res = {}
  let promise
  if (method === 'get' || method === 'delete') {
    promise = axios[method](`${API_URL}${url}`, {
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    })
  }

  if (method === 'post' || method === 'patch' || method === 'put') {
    promise = axios[method](`${API_URL}${url}`, data, {
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    })
  }
  if (promise) {
    await promise
      .then(function (response) {
        res = response.data
      })
      .catch(function (error) {
        res = error
      })
    return res
  }
}
