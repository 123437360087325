import styles from './ShoppingBagButton.module.scss'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBasketShopping } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import React from 'react'

const ShoppingBagButton = ({
  setShowShoppingBag,
  showShoppingBagButton,
}: {
  setShowShoppingBag: any
  showShoppingBagButton: boolean
}): JSX.Element => {
  const localPrice = JSON.parse(localStorage.getItem('localPrice') as string)
  const localTakeAwayModifier = JSON.parse(localStorage.getItem('localTakeAwayModifier') as string)
  const orderType = localStorage.getItem('orderType')
  const { t } = useTranslation(['restaurant'])
  return (
    <>
      {localPrice && showShoppingBagButton && (
        <div className={classnames('fixed-bottom text-center w-100 py-2', styles.backgroundWhite)}>
          <button
            className={classnames('btn rounded-pill text-uppercase', styles.shoppingButton)}
            onClick={() => {
              setShowShoppingBag(true)
            }}
          >
            <FontAwesomeIcon icon={faBasketShopping} className="me-2" /> {t('restaurantBag')}:{' '}
            {(orderType === 'takeAway' ? localPrice + (localTakeAwayModifier ? localTakeAwayModifier : 0) : localPrice)
              .toFixed(2)
              .replace('.', ',')}{' '}
            €
          </button>
        </div>
      )}
    </>
  )
}

export default ShoppingBagButton
