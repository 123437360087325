import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import commonEN from '../resources/locales/en/common.json'
import commonDE from '../resources/locales/de/common.json'
import homepageEN from '../resources/locales/en/homepage.json'
import homepageDE from '../resources/locales/de/homepage.json'
import restaurantEN from '../resources/locales/en/restaurant.json'
import restaurantDE from '../resources/locales/de/restaurant.json'
import bagEN from '../resources/locales/en/shoppingBag.json'
import bagDE from '../resources/locales/de/shoppingBag.json'
import confirmationEN from '../resources/locales/en/confirmation.json'
import confirmationDE from '../resources/locales/de/confirmation.json'

const resources = {
  en: {
    common: commonEN,
    homepage: homepageEN,
    restaurant: restaurantEN,
    legal: commonEN,
    shopping: bagEN,
    confirmation: confirmationEN,
  },
  de: {
    common: commonDE,
    homepage: homepageDE,
    restaurant: restaurantDE,
    legal: commonDE,
    shopping: bagDE,
    confirmation: confirmationDE,
  },
}
i18n.use(initReactI18next).init({
  defaultNS: 'common',
  resources,
  lng: 'de',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
