/* /components/Layout.js */

import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
import classnames from 'classnames'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Header from '../components/Header/Header'
import '../services/i18n'
import Footer from './Footer/Footer'
import styles from './layout.module.scss'
import ShoppingBagButton from './ShoppingBagButton/ShoppingBagButton'
import ShoppingBagModal from './ShoppingBagModal/ShoppingBagModal'

type LayoutProps = {
  children: React.ReactNode
}

config.autoAddCss = false

const Layout = ({
  children,
  showShoppingBag,
  shoppingBag,
  setShowShoppingBag,
  showShoppingBagButton,
  darkLogoOnPaths,
  setDarkLogoOnPaths,
}: {
  children: LayoutProps
  showShoppingBag: any
  shoppingBag: any
  setShowShoppingBag: any
  showShoppingBagButton: boolean
  darkLogoOnPaths: any
  setDarkLogoOnPaths: any
}): JSX.Element => {
  const { i18n } = useTranslation()
  const router = useRouter()
  const { locale } = router
  useEffect(() => {
    i18n.changeLanguage(locale)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  const title = 'sizzly'

  return (
    <Container fluid className="g-0 m-0 overflow-hidden">
      <Head>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <Header darkLogoOnPaths={darkLogoOnPaths} setDarkLogoOnPaths={setDarkLogoOnPaths} />
      {/* @ts-expect-error*/}
      <Row className={classnames('g-0', styles.contentChildren)}>{children}</Row>
      <Footer />
      {shoppingBag && (
        <ShoppingBagButton setShowShoppingBag={setShowShoppingBag} showShoppingBagButton={showShoppingBagButton} />
      )}
      {showShoppingBag && (
        <ShoppingBagModal showShoppingBag={showShoppingBag} setShowShoppingBag={setShowShoppingBag} />
      )}
    </Container>
  )
}

export default Layout
