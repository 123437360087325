import { Article } from '../types'

export const calcLocalItemsAmount = (dataObject: Article[], id: number) => {
  let articleAmount = 0
  if (dataObject && dataObject.length > 0) {
    for (var article of dataObject) {
      if (article.id === id) {
        articleAmount = articleAmount + (article.quantity ? article.quantity : 0)
      }
    }
  }
  return articleAmount
}
