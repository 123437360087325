import Image from 'next/image'
import styles from './LoadingAnimation.module.scss'
import classnames from 'classnames'

const LoadingAnimation = (props: any) => {
  return (
    <div className={props.loading || props.loading === null ? styles.loadingDiv : styles.displayNone}>
      <Image src={'/sizzlyLogo.svg'} width="100px" height="40px" alt="sizzly Logo" />
      <div className={styles.dotFlash} />
      {/*<div className="mt-3">{description}</div>*/}
    </div>
  )
}

export default LoadingAnimation
