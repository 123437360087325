import AdyenCheckout from '@adyen/adyen-web'
import '@adyen/adyen-web/dist/adyen.css'
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { makeRequest } from '../../services/axios'
import styles from './Payment.module.scss'
import { useRouter } from 'next/router'

declare const window: any

const Payment = ({
  orderId,
  setCheckoutStep,
  loading,
  setLoading,
  cashPayment,
  tipAmount,
  error,
  setError,
  t,
  discount,
  setDiscount,
  GUID,
  session,
  paymentProviderName,
}: {
  orderId: number
  setCheckoutStep: any
  loading: boolean
  setLoading: any
  cashPayment: boolean
  tipAmount: number
  error: string | null
  setError: any
  t: any
  discount: number
  setDiscount: any
  GUID: any
  session: any
  paymentProviderName: string
}) => {
  const localPrice = JSON.parse(localStorage.getItem('localPrice') as string)
  const localTakeAwayModifier = JSON.parse(localStorage.getItem('localTakeAwayModifier') as string)
  const orderType = localStorage.getItem('orderType')
  const id = localStorage.getItem('localRestaurantUid')
  const [cashPaymentExpand, setCashPaymentExpand] = useState(false)
  const [loadingPayInhouse, setLoadingPayInhouse] = useState(false)

  const { locale } = useRouter()

  const configuration = {
    environment: process.env.NEXT_PUBLIC_ADYEN_ENVIRONMENT, // Change to 'live' for the live environment.
    clientKey: process.env.NEXT_PUBLIC_ADYEN_CLIENT_KEY,
    locale: locale,
    session: {},
    onPaymentCompleted: (result: any, component: any) => {
      if (result.resultCode === 'Authorised') {
        window.location.href = `/${locale === 'de' ? '' : `${locale}/`}checkout/${id}/${GUID}/`
      }
    },
    onError: (error: any, component: any) => {
      console.error(error.name, error.message, error.stack, component)
    },
    // Any payment method specific configuration. Find the configuration specific to each payment method:  https://docs.adyen.com/payment-methods
    // For example, this is 3D Secure configuration for cards:
    paymentMethodsConfiguration: {
      card: {
        hasHolderName: true,
        holderNameRequired: true,
      },
    },
    /*onAdditionalDetails: (state: any, component: any) => {
      try {
        makeRequest('post', '/adyen-payment/paymentStatus', state)
          .then((succ) => {
            window.location.href = `/checkout/${id}/${orderId}/`
            return Promise.resolve()
          })

          .catch((error) => {
            console.log(error)
            // console.log(`err: ${JSON.stringify(error?.response)}`)
            window.location.href = `/checkout/${id}/${orderId}/`
            return Promise.reject(error.response)
          })
      } catch (error) {
        // console.log(error)
        window.location.href = `/checkout/${id}/${orderId}/`
      }
    },*/
  }

  const paymentContainer = useRef(null)

  useEffect(() => {
    if (!session || !paymentContainer.current || paymentProviderName !== 'adyen') {
      // initiateCheckout is not finished yet.
      console.error('nulled')
      return
    }

    setLoading(false)

    configuration.session = {
      id: session.message.sessionId,
      sessionData: session.message.sessionData,
    }

    const adyenSessionPrep = async () => {
      try {
        const checkout = await AdyenCheckout(configuration)
        if (paymentContainer.current) {
          checkout.create('dropin', { openFirstPaymentMethod: false }).mount(paymentContainer.current)
        }
      } catch (error) {
        console.log(error)
        // TODO: handle errors
        // @ts-expect-error
        if (error.response?.data?.code) {
          // @ts-expect-error
          const code = JSON.parse(error.response.data.code)
          if (code === 400.027 || 400.026 || 400.012 || 400.024 || 400.025) {
            setError(t('shopping:generalError'))
          }
        }
      } finally {
        setLoading(false)
      }
    }
    adyenSessionPrep()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, paymentContainer])

  const cashPaymentRequest = async () => {
    setLoadingPayInhouse(true)
    const cp = await makeRequest('put', `/orders/cashPayment/${orderId}`, 'CASH')
    if (cp.success) {
      window.location.href = `/${locale === 'de' ? '' : `${locale}/`}checkout/${id}/${GUID}/`
    } else {
      setError(t('shopping:chooseDifferentPaymentError'))
    }
    setLoadingPayInhouse(false)
  }

  useEffect(() => {
    async function paymentWidget() {
      window.wpwlOptions = {
        applePay: {
          displayName: 'sizzly',
          total: { label: 'sizzly GmbH' },
          style: 'black',
          currencyCode: 'EUR',
          merchantCapabilities: ['supports3DS'],
          supportedNetworks: ['masterCard', 'visa'],
          checkAvailability: 'canMakePayments',
          version: 1,
          countryCode: 'DE',
          shippingType: 'storePickup',
        },
        style: 'plain',
        locale: 'de',
        'threeDSecure.exemptionFlag': '01',
        threeDIframeSize: { width: '100%', height: '580px' },
        googlePay: {
          gatewayMerchantId: process.env.NEXT_PUBLIC_GOOGLE_GATEWAY_MERCHANT_ID,
          merchantId: process.env.NEXT_PUBLIC_GOOGLE_MERCHANT_ID,
          allowedCardNetworks: ['VISA', 'MASTERCARD'],
          merchantName: 'sizzly GmbH',
          allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
        },
        iframeStyles: {},
      }
      const script = document.createElement('script')
      script.src = `${process.env.NEXT_PUBLIC_VR_PAYMENT_URL}${session.message.sessionId}`
      script.async = true
      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
      }
    }
    if (paymentProviderName === 'vr-payment') paymentWidget()
  }, [])

  const displayCashPayment = () => {
    return (
      <div>
        <div className={styles.cashPaymentAlignment}>
          <span className={classNames(styles.sizzlyColor, 'my-auto')}>
            <FontAwesomeIcon icon={faMoneyBill} size={'lg'} />
          </span>{' '}
          <span className="ms-3">{t('shopping:cashPayment')}</span>
        </div>
        {cashPaymentExpand && (
          <button
            className="btn w-100 text-start bg-dark text-white mt-2 p-2 ps-3 text-center"
            onClick={async () => {
              await cashPaymentRequest()
            }}
            disabled={loadingPayInhouse}
          >
            {loadingPayInhouse ? (
              <span>
                <Spinner animation="border" size={'sm'} color={'white'} /> {t('shopping:loading')}
              </span>
            ) : (
              t('shopping:restaurantPayment')
            )}
          </button>
        )}
      </div>
    )
  }

  return (
    <div className="payment-container">
      {paymentProviderName === 'adyen' && <div ref={paymentContainer} className="payment"></div>}
      {paymentProviderName === 'vr-payment' && (
        <div>
          <form
            action={`${process.env.NEXT_PUBLIC_OWN_URL}/checkout/${id}/${GUID}/`}
            className="paymentWidgets"
            data-brands="APPLEPAY"
          />
          <form
            action={`${process.env.NEXT_PUBLIC_OWN_URL}/checkout/${id}/${GUID}/`}
            className="paymentWidgets"
            data-brands="GOOGLEPAY"
          />
          <div className="p-3 bg-light rounded">
            <form
              action={`${process.env.NEXT_PUBLIC_OWN_URL}/checkout/${id}/${GUID}/`}
              className="paymentWidgets"
              data-brands="VISA MASTER"
            />
          </div>
        </div>
      )}
      {cashPayment && !cashPaymentExpand && (
        <button
          className={classNames(
            'bg-white w-100 p-3 mt-2 align-content-center border-0 text-start',
            styles.roundedCorners
          )}
          onClick={() => setCashPaymentExpand(true)}
        >
          {displayCashPayment()}
        </button>
      )}
      {cashPayment && cashPaymentExpand && (
        <div className={classNames(' w-100 bg-white p-3 mt-2', styles.roundedCorners)}>{displayCashPayment()}</div>
      )}
      {error && <div className="alert alert-danger my-2">{error}</div>}
      <div
        className={classNames(
          'bg-white w-100 p-2 mt-2 col-6 align-self-center h4',
          styles.sizzlyColor,
          styles.roundedCorners
        )}
      >
        <span className="h6 ms-2">{t('shopping:price')}</span>{' '}
        {(orderType !== 'takeAway'
          ? localPrice + -discount + (tipAmount ? tipAmount : 0)
          : localPrice + -discount + (localTakeAwayModifier ? localTakeAwayModifier : 0) + (tipAmount ? tipAmount : 0)
        )
          .toFixed(2)
          .replace('.', ',')}{' '}
        €
      </div>
    </div>
  )
}

export default Payment
