import { makeRequest } from '../services/axios'
import { Article } from '../types'

export const orderRequest = async (
  id: number,
  tip: number | null,
  comment: null | string,
  paymentMethod: string | null,
  userName: string | null,
  userInformation: string | null,
  countryCode: number | string | null,
  phone: any,
  orderType: any,
  tableNumber: string | null,
  pickupDate: any,
  recaptcha: string,
  cartItems: Article[],
  quantity: number,
  appleUrl: string | null | undefined
) => {
  if (phone && countryCode && phone.toString().substring(0, countryCode.toString().length) === countryCode.toString()) {
    phone = phone.substring(countryCode.toString().length, phone.toString().length)
  }

  if (phone && phone.toString().substring(0, 1) === '0') {
    phone = phone.substring(1, phone.length)
  }

  let modifiedCartItems: any[] = []

  cartItems.forEach((item) => {
    let modifiedItem = {
      id: item.id,
      name: item.name,
      orderModule: item.orderModule,
      quantity: item.quantity,
      price: item.price,
      itemId: JSON.stringify(item.id),
      comment: item.comment ? item.comment : null,
      type: item.type,
    }
    modifiedCartItems.push(modifiedItem)
  })

  const body = {
    restaurant: id,
    orderPickupTime: pickupDate ? new Date(pickupDate) : undefined,
    paymentMethod: paymentMethod,
    orderTip: tip,
    orderStatus: 'active',
    orderItem: modifiedCartItems,
    orderVolume: quantity,
    orderTable: tableNumber ? parseInt(tableNumber) : null,
    orderType: orderType,
    orderComment: comment,
    purchaserDetails: {
      mail: userInformation ? userInformation.trim() : null,
      phone: parseInt(countryCode + phone),
      isGuest: true,
      name: userName,
    },
    recaptcha: recaptcha,
    appleUrl,
  }
  const response = await makeRequest('post', '/orders', body)
  return response
}
