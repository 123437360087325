import '../styles/globals.scss'
import type { AppProps } from 'next/app'
import Layout from '../components/layout'
import Script from 'next/script'
import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import LoadingAnimation from '../components/LoadingAnimation/LoadingAnimation'
import { faCookieBite } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'

declare var window: any

const Sizzly = ({ Component, pageProps }: AppProps) => {
  const [showShoppingBag, setShowShoppingBag] = useState(false)
  const [showShoppingBagButton, setShowShoppingBagButton] = useState(true)
  const [shoppingBag, setShoppingBag] = useState(null)
  const router = useRouter()
  const [loading, setLoading] = useState(false)
  const [updater, setUpdater] = useState(0)
  const [darkLogoOnPaths, setDarkLogoOnPaths] = useState(false)
  const [cookiesEnabled, setCookiesEnabled] = useState(true)
  const { t } = useTranslation(['common'])

  useEffect(() => {
    const handleStart = (url: string) => {
      url !== router.pathname && !url.includes('menu') && !url.includes('profile')
        ? setLoading(true)
        : setLoading(false)
    }
    const handleComplete = (url: string) => setLoading(false)

    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleComplete)
    router.events.on('routeChangeError', handleComplete)
  }, [router])

  useEffect(() => {
    if (navigator.cookieEnabled === false) {
      setCookiesEnabled(false)
    } else {
      setShoppingBag(JSON.parse(localStorage.getItem('cart') as string))
    }
  }, [])

  useEffect(() => {
    if (!router?.asPath?.includes('checkout')) {
      setShowShoppingBagButton(true)
    }
  }, [router.asPath])

  return (
    /* @ts-expect-error*/
    <Layout
      showShoppingBag={showShoppingBag}
      setShowShoppingBag={setShowShoppingBag}
      setShoppingBag={setShoppingBag}
      shoppingBag={shoppingBag}
      showShoppingBagButton={showShoppingBagButton}
      darkLogoOnPaths={darkLogoOnPaths}
      setDarkLogoOnPaths={setDarkLogoOnPaths}
    >
      <LoadingAnimation loading={loading} />
      {cookiesEnabled && (
        <Component
          {...pageProps}
          showShoppingBagButton={showShoppingBagButton}
          setShowShoppingBagButton={setShowShoppingBagButton}
          showShoppingBag={showShoppingBag}
          setShowShoppingBag={setShowShoppingBag}
          setShoppingBag={setShoppingBag}
          shoppingBag={shoppingBag}
          setUpdater={setUpdater}
          updater={updater}
          loading={loading}
          setDarkLogoOnPaths={setDarkLogoOnPaths}
        />
      )}
      {!cookiesEnabled && (
        <div className="cookiesDisabled">
          {t('common:cookiesNotAccepted')}
          <div className="m-3">
            <FontAwesomeIcon icon={faCookieBite} size={'8x'} color="#462fee" />
          </div>
        </div>
      )}
      <Script
        strategy="lazyOnload"
        src={`https://www.google.com/recaptcha/api.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}`}
        id={'grecaptcha'}
      ></Script>
      <Script
        strategy="afterInteractive"
        src="https://pay.google.com/gp/p/js/pay.js"
        onLoad={() => {
          window.googlePayClient = new google.payments.api.PaymentsClient({
            environment: `${process.env.NEXT_PUBLIC_GOOGLE_PAY_ENVIRONMENT}` as 'TEST' | 'PRODUCTION',
          })
        }}
      ></Script>
    </Layout>
  )
}

export default Sizzly
