import { Shifts, Day, OpeningTime, RestaurantScheduledClosing } from '../types'

export const OpeningHours = (shifts: Shifts, restaurantScheduledClosings: RestaurantScheduledClosing[]) => {
  const today = new Date()
  let openingTime: OpeningTime = {
    isOpen: false,
    isOpenStart: '',
    isOpenEnd: '',
    opensToday: false,
    opensTodayStart: [],
    opensTodayEnd: [],
    week: [],
  }
  for (const [key, value] of Object.entries(shifts)) {
    let shiftsPerDay: string[] = []
    if (typeof value === 'object') {
      for (const val of value as Day[]) {
        shiftsPerDay.push(val.startTime?.slice(0, 5) + '-' + val.endTime?.slice(0, 5))
      }
      openingTime?.week?.push(shiftsPerDay)
    }

    if (key?.toUpperCase() === today?.toLocaleDateString('en-EN', { weekday: 'long' }).toUpperCase()) {
      for (const time of value as Day[]) {
        if (time?.startTime < today?.toLocaleTimeString('de-DE') && today?.toLocaleTimeString('de-DE') < time.endTime) {
          if (restaurantScheduledClosings.length > 0) {
            for (const closings of restaurantScheduledClosings) {
              const closingTimeStart = new Date(closings?.restaurantScheduledClosingStart)
              const closingTimeEnd = new Date(closings?.restaurantScheduledClosingEnd)
              openingTime.isOpen = true
              openingTime.isOpenStart = time?.startTime
              openingTime.isOpenEnd = time?.endTime
              if (
                closingTimeEnd?.getMonth() >= today?.getMonth() &&
                today?.getMonth() >= closingTimeStart?.getMonth() &&
                closingTimeEnd?.getUTCDate() >= today?.getUTCDate() &&
                today?.getUTCDate() >= closingTimeStart?.getUTCDate() &&
                closingTimeStart?.getFullYear() === today?.getFullYear()
              ) {
                openingTime.isOpen = false
                openingTime.isOpenStart = ''
                openingTime.isOpenEnd = ''
              }
            }
          } else {
            openingTime.isOpen = true
            openingTime.isOpenStart = time?.startTime
            openingTime.isOpenEnd = time?.endTime
          }
        }
        if (
          time?.startTime > today?.toLocaleTimeString('de-DE') &&
          today?.toLocaleTimeString('de-DE') < time?.endTime
        ) {
          openingTime.opensToday = true
          openingTime.opensTodayStart.push(time?.startTime)
          openingTime.opensTodayEnd.push(time?.endTime)
        }
      }
    }
  }
  return openingTime
}
